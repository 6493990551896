<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">Questionnaire (12)</div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <stepForm :dialog="dialog" :form.sync="form" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" active-class="active" color="#2196f3">
          <v-tab
            v-for="item in tabItems"
            :key="item.tab"
            style="font-weight: 600"
            class="fn14"
          >
            <span>{{ item.tab }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItems" :key="item.tab" class="ma-2">
            <v-card class="rounded-lg">
              <v-card-title>
                <div class="fn16-weight7">{{ item.content }}</div>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                disable-pagination
                hide-default-footer
                class="elevation-1"
                style="font-weight: 700"
              >
                <template v-slot:header.count="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.name="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.visits="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.participation="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.completed="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.incomplete="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.updated="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.action="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.download="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:[`item.completed`]="{ item }">
                  <span class="fn14-weight7" style="color: green">{{
                    item.completed
                  }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-row justify="center" class="my-2">
                    <!-- <v-icon @click="Viewbanners(item)">mdi-eye</v-icon> -->
                    <v-btn
                      dark
                      outlined
                      block
                      class="rounded-xl fn12-weight7 mb-2"
                      style="color: #4f4f4f"
                    >
                      Report
                    </v-btn>
                    <v-btn
                      dark
                      outlined
                      block
                      class="rounded-xl fn12-weight7"
                      style="color: #4f4f4f"
                    >
                      QR Code
                    </v-btn>
                  </v-row>
                </template>
                <template v-slot:[`item.download`]="{ item }">
                  <v-btn
                    dark
                    outlined
                    class="rounded-xl fn12-weight7"
                    style="color: #07204b"
                  >
                    Download
                  </v-btn>
                </template>
              </v-data-table></v-card
            >
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import stepForm from "@/components/Shared/stepFormQuest";
export default {
  components: {
    draggable,
    Loading,
    stepForm,
  },
  data() {
    return {
      dialog: false,
      items: [],
      headers: [
        // { text: "", value: "", align: "center", width: "50" },
        { text: "#", value: "count", align: "center", width: "70" },
        { text: "Campaign Name", value: "name", width: "200" },
        { text: "Visits", value: "visits", align: "center", width: "90" },
        {
          text: "Participation",
          value: "participation",
          align: "center",
          width: "150",
        },
        {
          text: "Completed",
          value: "completed",
          align: "center",
          width: "130",
        },
        {
          text: "Incomplete",
          value: "incomplete",
          align: "center",
          width: "130",
        },
        {
          text: "Last Updated",
          value: "updated",
          align: "center",
          width: "150",
        },
        { text: "Action", value: "action", align: "center", width: "100" },
        { text: "Download", value: "download", align: "center", width: "130" },
      ],
      tab: null,
      tabItems: [
        {
          tab: "Active",
          content: "Active Questionnaires (3)",
          headers: [
            // { text: "", value: "", align: "center", width: "50" },
            { text: "#", value: "count", align: "center" },
            { text: "Campaign Name", value: "name", width: "200" },
            { text: "Visits", value: "visits", align: "center", width: "20" },
            { text: "Participation", value: "participation", align: "center" },
            { text: "Completed", value: "completed", align: "center" },
            { text: "Incomplete", value: "incomplete", align: "center" },
            {
              text: "Last Updated",
              value: "updated",
              align: "center",
              width: "150",
            },
            { text: "Action", value: "action", align: "center" },
            { text: "Download", value: "download", align: "center" },
          ],
          items: [
            {
              count: 1,
              name: "User Survey",
              visits: "100",
              participation: "100 (100%)",
              completed: "100 (100%)",
              incomplete: "0 (0,00%)",
              updated: "09/02/2020 17:00",
            },
            {
              count: 2,
              name: "User Survey",
              visits: "100",
              participation: "100 (100%)",
              completed: "100 (100%)",
              incomplete: "0 (0,00%)",
              updated: "09/02/2020 17:00",
            },
          ],
        },
        { tab: "Draft", content: "Draft" },
        { tab: "History", content: "History" },
      ],
      form: {
        name: "",
        date: new Date().toISOString().substr(0, 10),
        url: "",
        picture_1: "",
        menufrom: false,
        publish: false,
      },
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    this.getAllSurvey();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brandsssssss/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllSurvey() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/survey`
      );
      console.log("surveysurveysurveysurveysurvey", response.data);
      // this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items.count = parseInt(i) + 1;
      // }
      // console.log("item", this.items);
    },
    UpdateQuestionnaire(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("EditQuestionnaire");
    },
    ViewQuestionnaire(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewQuestionnaire");
    },
    async DeleteQuestionnaire(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/survey/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllSurvey();
        }
      });
    },
    goToCreate() {
      this.$router.push("createQuestionnaire");
    },
  },
};
</script>

<style scoped>
.active {
  /* color: rgba(255, 255, 255, 0) !important; */
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
