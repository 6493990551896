<template>
  <div>
    <ManageQuestionnaire />
  </div>
</template>
<script>
import ManageQuestionnaire from "@/components/Questionnaire/ManageQuestionnaire";
export default {
  components: {
    ManageQuestionnaire,
  },
  created() {},
};
</script>
